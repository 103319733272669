import React from 'react'
import gsap from 'gsap'
import DesktopFooter from 'templates/layout/footer'

const SlidingFooter = () => (
	<section
		id="sliding-footer-wrapper"
		className="absolute z-20 h-auto w-full bg-black"
		style={{ visibility: 'hidden' }}
	>
		<DesktopFooter />
	</section>
)

const buildSlidingFooterAnimation = (stickyVariantSetter) =>
	gsap
		.timeline({ id: 'footer-tl', defaults: { ease: 'none' } })
		.set('#sliding-footer-wrapper', { bottom: '-100%', autoAlpha: 1 }, 0)
		.call(() => stickyVariantSetter('quote', 'hidden', 'total-black'), null, 0.3)
		.to('#sliding-footer-wrapper', { bottom: 0, duration: 1.5 }, 0)

export { SlidingFooter, buildSlidingFooterAnimation }
