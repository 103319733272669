import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'
import CentralCube from '../central-cube'

function ReadyToStart() {
	const startX = 13
	const startY = 37.5

	useEffect(() => {
		// Infinite animation lives outside of scroll trigger,
		// only start/stop is toggled by the below callback
		gsap.set('#infinite-cube', { rotateX: startX, rotateY: startY })
		gsap.timeline({
			paused: true,
			repeat: -1,
			yoyo: true,
			defaults: { duration: 1.5, ease: 'none' },
			id: 'infinite-cube-tl',
		})
			.to('#infinite-cube', { rotateX: startX + 90, rotateY: startY + 90 })
			.to('#infinite-cube', { rotateX: startX + 180, rotateY: startY + 180 })
			.to('#infinite-cube', { rotateX: startX + 270, rotateY: startY + 270 })
			.to('#infinite-cube', { rotateX: startX + 360, rotateY: startY + 360 })
			.to('#infinite-cube', { rotateX: startX + 450, rotateY: startY + 450 })
			.to('#infinite-cube', { rotateX: startX + 540, rotateY: startY + 540 })
			.to('#infinite-cube', { rotateX: startX + 630, rotateY: startY + 630 })
			.to('#infinite-cube', { rotateX: startX + 720, rotateY: startY + 720 })
	})

	return (
		<section
			id="ready-to-start-wrapper"
			className="absolute top-0 z-[4] h-screen w-full overflow-hidden bg-accent"
			style={{ visibility: 'hidden' }}
		>
			<div className="relative h-full w-full">
				<CentralCube faceColor="bg-black" prefix="infinite" />

				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-pre-wrap text-center font-headings text-[1.75rem] font-semibold leading-tight text-white lg:top-auto lg:bottom-[var(--seeing-bottom)] lg:translate-y-0 lg:whitespace-nowrap lg:text-[6rem] lg:leading-none">
					Ready to start?{' '}
					<Link
						to="/contacts/"
						className="inline-block border-b-2 border-white lg:border-b-4"
						id="drop-a-line"
					>
						Drop us a line
					</Link>
				</div>
			</div>
		</section>
	)
}

const buildReadyToStartAnimation = (stickyVariantSetter) =>
	gsap
		.timeline({
			id: 'r2s-tl',
			defaults: { ease: 'none' },
			onStart: () => {
				gsap.getById('infinite-cube-tl').resume()
			},
			onReverseComplete: () => {
				gsap.getById('infinite-cube-tl').pause()
			},
		})
		.set('#ready-to-start-wrapper', { autoAlpha: 1 }, 0)
		.to('#our-partners-wrapper', { yPercent: -100, duration: 2 })
		.call(() => stickyVariantSetter('quote', 'total-black', 'black'), null, 0.15)
		.call(
			() =>
				stickyVariantSetter(
					'menu-bg',
					'bg-transparent',
					'bg-white transition-colors duration-500'
				),
			null,
			1.85
		)
		.set('#our-partners-wrapper', { autoAlpha: 0 })
// .to({}, { duration: 1 }) // pause to breathe

export { ReadyToStart, buildReadyToStartAnimation }
