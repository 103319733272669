import React from 'react'

const RightArrowThick = () => (
	<svg
		className="h-full w-full fill-current"
		viewBox="0 0 25 26"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m12 25.728-1.756-1.756 9.656-9.655H.59v-2.634H19.9l-9.656-9.655L12 .272 24.728 13z"
			fillRule="evenodd"
		/>
	</svg>
)

export default RightArrowThick
