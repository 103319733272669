import React, { useEffect } from 'react'
import gsap from 'gsap'
import { Rimg } from 'components/images'

// @refresh reset

/**
 * Parent must play/pause desired timelines, e.g.
 * gsap.getById('partners-marquee-backward-tl').pause()
 * gsap.getById('partners-marquee-forward-tl').play()
 */
export default function PartnersMarquee({ logos }) {
	// Inspired by https://codepen.io/GreenSock/pen/QEdpLe
	useEffect(() => {
		// TODO: include scrollbar width into calculation
		const deviceType = window.innerWidth >= 1024 ? 'desktop' : 'mobile'
		const stepCount = deviceType === 'desktop' ? 36 : 16
		const wUnit = (2 * window.innerWidth) / stepCount
		const totalLength = 2 * wUnit * 10
		gsap.set('.partner-logo', {
			x: (i) => i * 2 * wUnit,
		})

		const marqueeForwardTl = gsap
			.timeline({ id: 'partners-marquee-forward-tl', paused: true })
			.to('.partner-logo', {
				duration: deviceType === 'desktop' ? 20 : 15,
				ease: 'none',
				x: `-=${totalLength}`,
				modifiers: {
					x: gsap.utils.unitize(
						(x) => parseFloat(x + totalLength) % totalLength
					), // force x value to be between 0 and totalLength using modulus
				},
				repeat: -1,
			})

		const marqueeBackwardTl = gsap
			.timeline({ id: 'partners-marquee-backward-tl', paused: true })
			.to('.partner-logo', {
				duration: deviceType === 'desktop' ? 20 : 15,
				ease: 'none',
				x: `+=${totalLength}`,
				modifiers: {
					x: gsap.utils.unitize((x) => parseFloat(x) % totalLength), // force x value to be between 0 and totalLength using modulus
				},
				repeat: -1,
			})

		return () => {
			marqueeForwardTl.kill()
			marqueeBackwardTl.kill()
		}
	})

	return (
		<div className="relative h-[calc(2*var(--screen-grid-step))] w-full overflow-hidden bg-white">
			<div className="relative left-[calc(-4*var(--screen-grid-step))]">
				{logos.map((logo, index) => (
					<Rimg
						image={logo}
						position={2}
						mobilePosition={2}
						className={`partner-logo absolute opacity-40`}
						key={logo.url}
					/>
				))}
			</div>
		</div>
	)
}
