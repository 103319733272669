import React from 'react'
import { graphql } from 'gatsby'
import HomePageTpl from 'templates/home/page-v2'

export default function IndexPage({ data }) {
	const { section_title2, block_headers, block_paragraphs, client_logos } =
		data.prismicHomePage.data
	const { hero_heading, hero_desktop_video, hero_mobile_video, ...v2Structure } =
		data.prismicHomePageV2.data
	const content = {
		hero: {
			heading: hero_heading,
			desktopVideoUrl: hero_desktop_video.url,
			mobileVideoUrl: hero_mobile_video.url,
		},
		whyUs: {
			heading: section_title2,
			blocks: block_headers.map((blockItem, index) => ({
				heading: blockItem.block_header,
				paragraph: block_paragraphs[index].block_paragraph,
			})),
		},
		partnerLogos: client_logos.map(({ logo }) => logo),
	}

	return <HomePageTpl {...content} {...v2Structure} />
}

export const query = graphql`
	query homePageQuery {
		prismicHomePageV2 {
			data {
				slogan
				hero_heading
				hero_desktop_video {
					url
				}
				hero_mobile_video {
					url
				}
				main_services {
					heading_link
					heading
					button_text
					button_link
					background_video {
						url
					}
					background {
						url
						dimensions {
							height
							width
						}
					}
				}
				partner_with_us {
					button_link
					button_text
					image {
						alt
						url
						dimensions {
							height
							width
						}
					}
					subheading
					paragraph {
						richText
					}
				}
			}
		}
		prismicHomePage {
			data {
				block_paragraphs {
					block_paragraph {
						richText
					}
				}
				block_headers {
					block_header
				}
				client_logos {
					logo {
						url
						dimensions {
							height
							width
						}
					}
				}
				section_title2
				services {
					heading
					page_link
					paragraph
				}
				testimonials_heading
			}
		}
	}
`
