import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import gsap from 'gsap'
import getDeviceType from 'hooks/device-type'
import { CtaLink } from 'components/design'

const PartnerWithUs = ({ heading, blocks, contentItems }) => (
	<section
		id="partner-with-us-wrapper"
		className="absolute top-0 h-screen w-full overflow-hidden bg-white"
		style={{ visibility: 'hidden' }}
	>
		<div className="relative hidden h-full w-full lg:flex">
			<h2
				id="pwu-h2"
				className="absolute top-40 left-[14%] z-[4] w-1/4 font-headings text-4xl font-semibold leading-none lg:text-6xl lg:leading-none"
			>
				{heading}
			</h2>

			{blocks.map(({ heading, paragraph }, index) => (
				<React.Fragment key={heading}>
					<h3
						id={`pwu-h3-${index + 1}`}
						className="absolute top-[45%] left-[14%] w-1/4 font-headings text-xl font-semibold leading-tight xl:text-2xl xl:leading-tight"
						style={index > 0 ? { visibility: 'hidden' } : {}}
					>
						{heading}
					</h3>
					<div
						id={`pwu-paragraph-${index + 1}`}
						className={`regular-text absolute left-[14%] w-1/4 text-sm leading-relaxed text-pale lg:text-base lg:leading-relaxed`}
						style={{
							visibility: index > 0 ? 'hidden' : 'inherit',
							top: index === 2 ? '53%' : '51%',
						}}
					>
						<PrismicRichText field={paragraph.richText} />
					</div>
					<div
						className={`absolute left-[14%] top-[72%] overflow-hidden`}
						id={`get-in-touch`}
						style={{ visibility: 'hidden', width: 0 }}
					>
						<CtaLink
							className="!w-full"
							to="/contacts/"
							label="Get in touch"
							id={`get-in-touch-cta`}
						/>
					</div>
				</React.Fragment>
			))}

			<div
				id="pwu-number-1"
				className="absolute top-[45%] left-[var(--screen-grid-step)] flex flex-col font-headings text-2xl font-semibold leading-tight"
				style={{ opacity: 1 }}
			>
				01
			</div>
			<div
				id="pwu-number-2"
				className="absolute top-[51.5%] left-[var(--screen-grid-step)] flex flex-col font-headings text-2xl font-semibold leading-tight"
				style={{ opacity: 0.25 }}
			>
				02
			</div>
			<div
				id="pwu-number-3"
				className="absolute top-[58%] left-[var(--screen-grid-step)] flex flex-col font-headings text-2xl font-semibold leading-tight"
				style={{ opacity: 0.1 }}
			>
				03
			</div>

			<div
				id="pwu-img-1-wrapper"
				className="absolute top-0 left-1/2 h-full w-1/2 overflow-hidden"
			>
				<div
					id="pwu-img-1"
					className="h-full w-full bg-cover bg-center"
					style={{
						backgroundImage: `url(${contentItems[0].image.url + '&w=1400'})`,
					}}
				/>
			</div>
			<div
				id="pwu-img-2-wrapper"
				className="absolute left-1/2 h-full w-1/2 overflow-hidden"
				style={{ visibility: 'hidden', top: '100%' }}
			>
				<div
					id="pwu-img-2"
					className="h-full w-full bg-cover bg-center"
					style={{
						backgroundImage: `url(${contentItems[1].image.url + '&w=1400'})`,
					}}
				/>
			</div>
			<div
				id="pwu-img-3-wrapper"
				className="absolute left-1/2 h-full w-1/2 overflow-hidden"
				style={{ visibility: 'hidden', top: '100%' }}
			>
				<div
					id="pwu-img-3"
					className="h-full w-full bg-cover bg-center"
					style={{
						backgroundImage: `url(${contentItems[2].image.url + '&w=1400'})`,
					}}
				/>
			</div>
		</div>

		<div className="relative mt-screen-step-4 h-full w-full lg:hidden">
			<h2 className="mb-8 ml-screen-step font-headings text-2xl font-semibold leading-none lg:text-6xl lg:leading-none">
				{heading}
			</h2>

			<div className="scrollbar-hidden h-auto overflow-x-auto">
				<div id="m-pwu-swiper" className="flex flex-nowrap pr-screen-step">
					{blocks.map(({ heading, paragraph }, index) => (
						<div
							className={`w-screen-step-10 flex-none ${
								index === 0 ? 'ml-screen-step' : 'ml-screen-step-2'
							}`}
							key={heading}
						>
							<div
								id={`m-pwu-img-${index + 1}`}
								className="h-screen-step-7 w-screen-step-8 bg-cover bg-center"
								style={{
									backgroundImage: `url(${
										contentItems[index].image.url + '&w=400'
									})`,
								}}
							/>
							<h3 className="mt-4 w-3/4 font-headings text-xl font-semibold leading-tight">
								{heading}
							</h3>
							<div
								className={`regular-text mt-4 text-sm leading-relaxed text-pale lg:text-base lg:leading-relaxed`}
							>
								<PrismicRichText field={paragraph.richText} />
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	</section>
)

const buildPartnerWithUsAnimation = (stickyVariantSetter) =>
	getDeviceType() === 'desktop'
		? gsap
				.timeline({ id: 'pwu-tl', defaults: { ease: 'none' } })
				.set('#partner-with-us-wrapper', {
					autoAlpha: 1,
					yPercent: 100,
				})
				.to('#partner-with-us-wrapper', {
					yPercent: 0,
					duration: 2.5,
				})
				.set(
					['#ani-wrapper', '#seeing', '#ani-heading', '#ani-see-more'],
					{ autoAlpha: 0 },
					2.5
				)
				.from('#pwu-h2', { top: '60%', autoAlpha: 0, duration: 1 }, 1.5)
				.from('#pwu-h3-1', { top: '100%', autoAlpha: 0, duration: 1 }, 1.75)
				.from('#pwu-number-1', { top: '100%', duration: 1 }, '<')
				.from('#pwu-paragraph-1', { top: '100%', autoAlpha: 0, duration: 1 }, 2)
				.from('#pwu-number-2', { top: '100%', duration: 1 }, '<')
				.from('#pwu-number-3', { top: '100%', duration: 1 }, 2.25)
				.from('#pwu-img-1-wrapper', { autoAlpha: 0, duration: 1.25 }, 0.25)
				.from('#pwu-img-1', { scale: 1.5, duration: 3.5 }, 0.5)
				// .to({}, { duration: 1 }) // pause to breathe
				.addLabel('pwu2Start')
				.to('#pwu-h3-1', { top: '25%', autoAlpha: 0, duration: 1 }, `pwu2Start`)
				.to('#pwu-paragraph-1', { top: '31.5%', autoAlpha: 0, duration: 1 }, '<')
				.from('#pwu-h3-2', { top: '75%', duration: 1 }, `pwu2Start+=${0.25}`)
				.to('#pwu-h3-2', { autoAlpha: 1, duration: 1 }, '<')
				.from(
					'#pwu-paragraph-2',
					{ top: '81%', duration: 1 },
					`pwu2Start+=${0.5}`
				)
				.to('#pwu-paragraph-2', { autoAlpha: 1, duration: 1 }, '<')
				.to('#pwu-number-1', { opacity: 0.25, duration: 1 }, `pwu2Start+=${0.25}`)
				.to('#pwu-number-2', { opacity: 1, duration: 1 }, `pwu2Start+=${0.5}`)
				.to('#pwu-number-3', { opacity: 0.25, duration: 1 }, `pwu2Start+=${0.75}`)
				.to('#pwu-img-1', { scale: 0.7, duration: 1.5 }, `pwu2Start`)
				.to('#pwu-img-1-wrapper', { autoAlpha: 0, duration: 1.5 }, `pwu2Start`)
				.set('#pwu-img-2-wrapper', { autoAlpha: 1 }, `pwu2Start`)
				.to('#pwu-img-2-wrapper', { top: 0, duration: 1.5 }, `pwu2Start`)
				.from('#pwu-img-2', { scale: 1.5, duration: 2 }, `pwu2Start+=${0.5}`)
				// .to({}, { duration: 1 }) // pause to breathe
				.addLabel('pwu3Start')
				.to('#pwu-h3-2', { top: '25%', autoAlpha: 0, duration: 1 }, `pwu3Start`)
				.to('#pwu-paragraph-2', { top: '31.5%', autoAlpha: 0, duration: 1 }, '<')
				.from('#pwu-h3-3', { top: '75%', duration: 1 }, `pwu3Start+=${0.25}`)
				.to('#pwu-h3-3', { autoAlpha: 1, duration: 1 }, '<')
				.from(
					'#pwu-paragraph-3',
					{ top: '83%', duration: 1 },
					`pwu3Start+=${0.5}`
				)
				.to('#pwu-paragraph-3', { autoAlpha: 1, duration: 1 }, '<')
				.to('#pwu-number-1', { opacity: 0.1, duration: 1 }, `pwu3Start+=${0.25}`)
				.to('#pwu-number-2', { opacity: 0.25, duration: 1 }, `pwu3Start+=${0.5}`)
				.to('#pwu-number-3', { opacity: 1, duration: 1 }, `pwu3Start+=${0.75}`)
				.to('#pwu-img-2', { scale: 0.7, duration: 1.5 }, `pwu3Start`)
				.to('#pwu-img-2-wrapper', { autoAlpha: 0, duration: 1.5 }, `pwu3Start`)
				.set('#pwu-img-3-wrapper', { autoAlpha: 1 }, `pwu3Start`)
				.to('#pwu-img-3-wrapper', { top: 0, duration: 1.5 }, `pwu3Start`)
				.from('#pwu-img-3', { scale: 1.5, duration: 2 }, `pwu3Start+=${0.5}`)
				.set('#get-in-touch', { autoAlpha: 1 }, `pwu3Start+=${1.5}`)
				.to('#get-in-touch', { width: 320, duration: 0.5 }, `pwu3Start+=${1.5}`)
				// .to({}, { duration: 1 }) // pause to breathe
				.call(() => stickyVariantSetter('line', false, true), null, 1)
				.call(
					() => {
						stickyVariantSetter('logo', 'text-black', 'text-white')
						stickyVariantSetter('menu-text', 'text-black', 'text-white')
						stickyVariantSetter(
							'menu-bg',
							'bg-white transition-colors duration-500',
							'bg-transparent'
						)

						const videoEl = document.querySelector('#ani-video')

						videoEl.paused ? videoEl.play() : videoEl.pause()
					},
					null,
					2.5
				)
		: gsap
				.timeline({ id: 'pwu-tl', defaults: { ease: 'none' } })
				.set('#partner-with-us-wrapper', {
					autoAlpha: 1,
					yPercent: 100,
				})
				.to('#partner-with-us-wrapper', {
					yPercent: 0,
					duration: 2,
				})
				.set(
					['#ani-wrapper', '#seeing', '#ani-heading', '#ani-see-more'],
					{ autoAlpha: 0 },
					2
				)
				// .to('#pwu-swiper', { xPercent: -125, duration: 3 }, 2.5)
				.to({}, { duration: 1 })
				.call(() => stickyVariantSetter('line', false, true), null, 1)
				.call(
					() => {
						stickyVariantSetter('logo', 'text-black', 'text-white')
						stickyVariantSetter('menu-text', 'text-black', 'text-white')
						stickyVariantSetter(
							'menu-bg',
							'bg-white transition-colors duration-500',
							'bg-transparent'
						)
						stickyVariantSetter('quote', 'black', 'white')

						const videoEl = document.querySelector('#ani-video')

						videoEl.paused ? videoEl.play() : videoEl.pause()
					},
					null,
					2
				)

export { PartnerWithUs, buildPartnerWithUsAnimation }
