import React, { useEffect, useMemo } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import getDeviceType from 'hooks/device-type'
import HeroVideo from './slides/hero-video'
import { ControlledCube, buildControlledCubeAnimation } from './slides/controlled-cube'
import { OurPartners, buildOurPartnersAnimation } from './slides/our-partners'
import { PartnerWithUs, buildPartnerWithUsAnimation } from './slides/partner-with-us'
import { ReadyToStart, buildReadyToStartAnimation } from './slides/ready-to-start'
import { ServiceElements, buildServiceAnimations } from './slides/service-elements'
import { SlidingFooter, buildSlidingFooterAnimation } from './slides/sliding-footer'

// @refresh reset
gsap.registerPlugin(ScrollTrigger)

export default function SlideScroller({
	hero,
	partnerLogos,
	whyUs,
	main_services,
	partner_with_us,
	stickyVariantSetter,
}) {
	const deviceType = useMemo(() => getDeviceType(), [])

	useEffect(() => {
		const slidesTl = gsap.timeline({ paused: true })
		slidesTl.add(buildControlledCubeAnimation())
		buildServiceAnimations().forEach((tlBuilder) => slidesTl.add(tlBuilder()))
		slidesTl.add(buildPartnerWithUsAnimation(stickyVariantSetter))
		slidesTl.add(buildOurPartnersAnimation(stickyVariantSetter))
		slidesTl.add(buildReadyToStartAnimation(stickyVariantSetter))
		slidesTl.add(buildSlidingFooterAnimation(stickyVariantSetter))

		const durations = [
			gsap.getById('cc-tl').totalDuration(),
			gsap.getById('ii1-tl').totalDuration(),
			gsap.getById('av-tl').totalDuration(),
			gsap.getById('pwu-tl').totalDuration(),
			gsap.getById('op-tl').totalDuration(),
			gsap.getById('r2s-tl').totalDuration(),
			gsap.getById('footer-tl').totalDuration(),
		]
		const totalDuration = durations.reduce((carry, dur) => carry + dur, 0)
		const scrollDuration = (
			(totalDuration * 100) /
			(deviceType === 'desktop' ? 3.6 : 3.2)
		).toFixed(2) // still a string
		console.log(totalDuration, scrollDuration)
		console.log('Controlled cube Tl', gsap.getById('cc-tl').totalDuration())
		console.log('Interior img 1 Tl', gsap.getById('ii1-tl').totalDuration())
		console.log('Animation video Tl', gsap.getById('av-tl').totalDuration())
		console.log('Partner with us Tl', gsap.getById('pwu-tl').totalDuration())
		console.log('Our partners Tl', gsap.getById('op-tl').totalDuration())
		console.log('Ready to start Tl', gsap.getById('r2s-tl').totalDuration())
		console.log('Footer Tl', gsap.getById('footer-tl').totalDuration())

		const toggleBrightness = (el) => el.classList.toggle('brightness-[0.6]')
		const filterHandler = (selector) => () => {
			const wrapper = document.querySelector(selector)
			if (wrapper.style.visibility === 'inherit') {
				toggleBrightness(wrapper)
			}
		}

		if (deviceType === 'desktop') {
			const extHandler = filterHandler('#ext-i1-wrapper')
			const intHandler = filterHandler('#int-i1-wrapper')
			const aniHandler = filterHandler('#ani-wrapper')
			document
				.querySelector('#ext-heading')
				.addEventListener('mouseenter', extHandler)
			document
				.querySelector('#ext-heading')
				.addEventListener('mouseleave', extHandler)
			document
				.querySelector('#int-heading')
				.addEventListener('mouseenter', intHandler)
			document
				.querySelector('#int-heading')
				.addEventListener('mouseleave', intHandler)
			document
				.querySelector('#ani-heading')
				.addEventListener('mouseenter', aniHandler)
			document
				.querySelector('#ani-heading')
				.addEventListener('mouseleave', aniHandler)
		}

		const st = ScrollTrigger.create({
			animation: slidesTl,
			trigger: 'main',
			id: 'main',
			scrub: deviceType === 'desktop' ? true : 0.1,
			start: 'top top',
			end: `+=${scrollDuration}% bottom`,
			pin: true,
		})

		return () => st.kill()
	}, [deviceType, stickyVariantSetter])

	// force re-rendering in browser, cause SSR cannot figure device type properly
	if (typeof deviceType !== 'string') {
		return null
	}

	return (
		/** ScrollTrigger must remove its pin-spacer div during cleanup on onmounting.
		 * An error appears when this div is the root of the component.
		 * Thus, we have to wrap the trigger main with an extra empty element.
		 */
		<div>
			<main className="h-screen w-full overflow-hidden">
				<HeroVideo {...hero} />
				<ControlledCube />
				<ServiceElements services={main_services} />
				<PartnerWithUs {...whyUs} contentItems={partner_with_us} />
				<OurPartners partnerLogos={partnerLogos} />
				<ReadyToStart />
				<SlidingFooter />
			</main>
		</div>
	)
}
