import React, { useEffect, createRef, useState } from 'react'
import gsap from 'gsap'
import getDeviceType from 'hooks/device-type'
// @refresh reset

const faceRotations = [
	{ ry: 0, rx: 0 },
	{ ry: 90, rx: 0 },
	{ ry: 180, rx: 0 },
	{ ry: 270, rx: 0 },
	{ ry: 0, rx: 90 },
	{ ry: 0, rx: -90 },
]

export default function CentralCube({ faceColor, prefix }) {
	const deviceType = getDeviceType()
	const [side, setSide] = useState(0)

	const faceRefs = faceRotations.map(() => createRef())

	useEffect(() => {
		if (deviceType === 'desktop') {
			setSide(Math.ceil(0.4 * window.innerHeight))
		} else if (deviceType === 'mobile') {
			setSide(Math.ceil(0.52 * window.innerWidth))
		}
	}, [deviceType])

	useEffect(() => {
		if (!side) return

		// apply transform rotations to each face of the cube
		faceRefs.forEach((faceRef, i) => {
			gsap.set(faceRef.current, {
				rotateX: faceRotations[i].rx,
				rotateY: faceRotations[i].ry,
			})
		})
	}, [faceRefs, side])

	return (
		<div
			className={`${prefix}-cube-container absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
			style={{
				height: `${side}px`,
				width: `${side}px`,
				perspective: `${3 * side}px`,
			}}
		>
			<div
				id={`${prefix}-cube`}
				className="absolute h-full w-full"
				style={{ transformStyle: 'preserve-3d' }}
			>
				{side &&
					faceRotations.map(({ rx, ry }, index) => (
						<div
							className={`${prefix}-face ${faceColor} absolute h-full w-full`}
							style={{
								borderRadius: '7px',
								transformStyle: 'preserve-3d',
								transformOrigin: `50% 50% -${0.5 * side}px`,
								transform: `translate3d(0px, 0px, ${0.5 * side}px)`,
							}}
							ref={faceRefs[index]}
							key={`${rx}${ry}`}
						/>
					))}
			</div>
		</div>
	)
}
