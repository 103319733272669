import React, { useEffect } from 'react'
import FullScreenVideo from 'components/full-screen-video'
import getDeviceType from 'hooks/device-type'

export default function HomeHeroVideo({ heading, desktopVideoUrl, mobileVideoUrl }) {
	const deviceType = getDeviceType()
	const videoUrl =
		deviceType === 'desktop'
			? desktopVideoUrl
			: deviceType === 'mobile'
			? mobileVideoUrl
			: null

	useEffect(() => {
		const videoEl = document.querySelector('#hero-video')
		videoEl && videoEl.play()
	})

	return (
		<section id="hero-video-wrapper" className="absolute top-0 h-screen w-full">
			<div id="hero-video-container" className="h-full w-full">
				{videoUrl && <FullScreenVideo id="hero-video" src={videoUrl} />}
			</div>
			<h1 className="absolute top-[69%] left-screen-step whitespace-pre-wrap font-headings text-[2rem] font-semibold leading-[1.1] text-white lg:bottom-screen-step lg:left-screen-step-19 lg:top-auto lg:text-6xl">
				{h1parts(heading)}
			</h1>
		</section>
	)
}

function h1parts(text) {
	const parts = text.split('&')
	const contentArr = []

	if (parts.length > 1) {
		contentArr.push(parts[0])
		contentArr.push('\n')
		contentArr.push(
			<span key="amp-span" style={{ fontVariantCaps: 'titling-caps' }}>
				&amp;
			</span>
		)
		contentArr.push(parts[1])
	} else {
		contentArr.push(text)
	}

	return contentArr
}
