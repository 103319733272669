import React from 'react'

const FullScreenVideo = ({ id, src }) => (
	<video
		id={id}
		className="block h-full w-full object-cover"
		loop
		muted
		playsInline
		preload="metadata"
		src={src}
	/>
)

export default FullScreenVideo
