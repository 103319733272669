import React from 'react'
import gsap from 'gsap'
import { Heading2 } from 'components/design'
import PartnersMarquee from 'embeds/partners-marquee'
import Testimonials from 'embeds/testimonials'

const OurPartners = ({ partnerLogos }) => (
	<section
		id="our-partners-wrapper"
		className="absolute top-0 z-[5] h-screen w-full overflow-hidden bg-white"
		style={{ visibility: 'hidden' }}
	>
		<div className="relative h-full w-full">
			<Heading2 className="ml-screen-step mt-screen-step-4 mb-screen-step-2 lg:ml-screen-step-9 lg:mb-20 lg:mt-36">
				Our partners
			</Heading2>
			<Testimonials />
			<div className="h-6 w-full" />

			<PartnersMarquee logos={partnerLogos} />
		</div>
	</section>
)

const buildOurPartnersAnimation = (stickyVariantSetter) =>
	gsap
		.timeline({ id: 'op-tl', defaults: { ease: 'none' } })
		.set('#our-partners-wrapper', { yPercent: 100, autoAlpha: 1 }, 0)
		.to('#partner-with-us-wrapper', { autoAlpha: 0, duration: 2 }, 0)
		.to('#our-partners-wrapper', { yPercent: 0, duration: 2 }, 0)
		.call(
			() => {
				stickyVariantSetter('quote', 'black', 'white')

				const marqueeForwardTl = gsap.getById('partners-marquee-forward-tl')
				const marqueeBackwardTl = gsap.getById('partners-marquee-backward-tl')

				if (marqueeForwardTl.paused() && marqueeBackwardTl.paused()) {
					// first scroll down
					marqueeForwardTl.play()
				} else if (!marqueeForwardTl.paused() && marqueeBackwardTl.paused()) {
					// back before finishing the screen
					marqueeForwardTl.pause()
				} else if (marqueeForwardTl.paused() && !marqueeBackwardTl.paused()) {
					// back from previous screen
					marqueeBackwardTl.pause()
				}
			},
			null,
			0
		)
		.to({}, { duration: 1 }) // pause to breathe

export { OurPartners, buildOurPartnersAnimation }
