import React from 'react'
import gsap from 'gsap'
import getDeviceType from 'hooks/device-type'
import CentralCube from '../central-cube'

// @refresh reset

const startX = 13
const startY = 37.5

const ControlledCube = () => (
	<section
		id="controlled-cube-wrapper"
		className="absolute top-0 h-screen w-full overflow-hidden bg-black will-change-transform"
		style={{ visibility: 'hidden' }}
	>
		<CentralCube faceColor="bg-accent" prefix="controlled" />
	</section>
)

const buildControlledCubeAnimation = () =>
	gsap
		.timeline({ id: 'cc-tl', defaults: { ease: 'none' } })
		.set('#controlled-cube-wrapper', { autoAlpha: 1 }, 0)
		.to('#hero-video-wrapper', { autoAlpha: 0, duration: 2.5 }, 0)
		.call(
			() => {
				const videoEl = document.querySelector('#hero-video')
				videoEl.paused ? videoEl.play() : videoEl.pause()
			},
			null,
			2.5
		)
		.from('#controlled-cube-wrapper', { yPercent: 100, duration: 2.5 }, 0)
		.addLabel('rotation1', 2)
		.addLabel('rotation2', 4)
		.addLabel('rotation3', 6)
		.addLabel('rotation4', 8)
		.addLabel('rotation5', 10)
		.to(
			'.seeing-word',
			{ autoAlpha: 1, stagger: 2 / 3, duration: 2 / 3 },
			`rotation1+=${0.5}`
		) // total duration of 2: 3 words + 2 staggers
		.add(
			getDeviceType() === 'desktop'
				? gsap.to('#seeing', {
						transform: 'translate(0)',
						left: () => window.innerWidth / 36, // 1 screen grid step
						fontSize: 24,
						duration: 1,
				  })
				: gsap
						.timeline({ defaults: { ease: 'none' } })
						.to(
							'#seeing',
							{ top: () => (4 * window.innerWidth) / 16, duration: 0.5 },
							`-=${0.2}`
						)
						.to('#seeing', { fontSize: 17, duration: 0.5 }),
			`rotation3`
		)
		.to(
			'.ext-heading-word',
			{ autoAlpha: 1, stagger: 0.75, duration: 0.75 }, // total duration of 1.5: 2 words + 1 stagger
			`rotation3+=${0.5}`
		)
		.set('#ext-heading', { autoAlpha: 1 }, '<')
		.to('#ext-i1-wrapper', { autoAlpha: 1, duration: 1 }, `rotation4+=${1}`)
		.add(
			getDeviceType() === 'desktop'
				? gsap
						.timeline({ defaults: { ease: 'none' } })
						.to('#ext-see-more', { width: 320, duration: 1 }, `rotation5`)
						.set('#ext-see-more', { autoAlpha: 1 }, '<')
				: gsap
						.timeline({ defaults: { ease: 'none' } })
						.to('#ext-heading', { top: '-=96px', duration: 0.5 })
						.set('#ext-see-more', { autoAlpha: 1 }, '<')
						.to(
							'#ext-see-more',
							{ width: () => (14 * window.innerWidth) / 16, duration: 1.5 },
							`+=${0.5}`
						),
			`rotation5`
		)

		.fromTo(
			'#controlled-cube',
			{ rotateX: startX, rotateY: startY },
			{ rotateX: startX + 90, rotateY: startY + 90, duration: 2 },
			0
		)
		.to(
			'#controlled-cube',
			{ rotateX: startX + 90 * 2, rotateY: startY + 90 * 2, duration: 2 },
			'rotation1'
		)
		.to(
			'#controlled-cube',
			{ rotateX: startX + 90 * 3, rotateY: startY + 90 * 3, duration: 2 },
			'rotation2'
		)
		.to(
			'#controlled-cube',
			{ rotateX: startX + 90 * 4, rotateY: startY + 90 * 4, duration: 2 },
			'rotation3'
		)
		.to(
			'#controlled-cube',
			{ rotateX: startX + 90 * 5, rotateY: startY + 90 * 5, duration: 2 },
			'rotation4'
		)
		.to('#controlled-cube-wrapper', { scale: 1.25, duration: 1 }, `rotation4`)
		.to(
			'#controlled-cube-wrapper',
			{ scale: 3, autoAlpha: 0, duration: 1 },
			`rotation4+=${1}`
		)

export { ControlledCube, buildControlledCubeAnimation }
