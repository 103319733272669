import React from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'
import { CtaLink } from 'components/design'
import FullScreenVideo from 'components/full-screen-video'
import getDeviceType from 'hooks/device-type'
import RightArrow from 'svg/right-arrow-thick'

const headingLinks = {
	ext: '/services/exterior-rendering/',
	int: '/services/interior-rendering/',
	ani: '/services/architectural-animation/',
}
const portfolioLinks = {
	ext: '/projects/tag/exterior/',
	int: '/projects/tag/interior/',
	ani: '/projects/tag/animation/',
}
const videoUrl =
	'https://cuub.cdn.prismic.io/cuub/f8ef2270-1c60-42f9-afc2-e230b00823e9_home-hero.mp4'

const ImgElement = ({ code, imageUrl }) => (
	<div
		id={`${code}-wrapper`}
		className="absolute top-0 h-screen w-full overflow-hidden duration-200 ease-in-out will-change-transform"
		style={{ transition: 'filter', visibility: 'hidden' }}
	>
		<div
			id={code}
			className="h-full w-full bg-cover bg-center will-change-transform"
			style={{
				backgroundImage: `url(${
					imageUrl + '&w=' + (getDeviceType() === 'desktop' ? '2800' : '2000')
				})`,
			}}
		/>
	</div>
)

const HeadingElement = ({ code, words }) => (
	<Link
		to={headingLinks[code]}
		id={`${code}-heading`}
		className="group absolute left-screen-step top-[69%] whitespace-pre-wrap font-headings text-[2rem] font-semibold leading-[1.1] text-white lg:top-auto lg:bottom-[var(--seeing-bottom)] lg:left-screen-step-19 lg:text-6xl lg:leading-none"
		style={{ visibility: 'hidden' }}
	>
		{/* Underline animation inspired by https://www.30secondsofcode.org/css/s/hover-underline-animation */}
		<div
			className={`${code}-heading-word relative inline-block after:absolute after:bottom-0.5 after:h-1 after:w-full after:origin-left after:scale-x-0 after:bg-white after:transition-transform after:duration-300 after:content-[''] group-hover:after:scale-x-100 ${
				code === 'ani' ? 'after:left-0' : 'after:left-[3px]'
			}`}
			style={{ visibility: 'hidden' }}
		>
			{words[0]}
		</div>
		<div
			className={`${code}-heading-word flex items-center`}
			style={{ visibility: 'hidden' }}
		>
			<span
				className={`relative after:absolute after:bottom-0.5 after:h-1 after:origin-left after:scale-x-0 after:bg-white after:transition-transform after:duration-300 after:content-[''] group-hover:after:scale-x-100 ${
					code === 'ani'
						? 'after:left-0 after:w-full'
						: 'after:left-[3px] after:w-[86%]'
				}`}
			>
				{words[1]}
			</span>
			<i className="duration-400 ml-2 inline-block h-[0.7em] w-[0.7em] -rotate-45 transition-transform group-hover:rotate-0">
				<RightArrow />
			</i>
		</div>
	</Link>
)

const ButtonElement = ({ code, label, to }) => (
	<div
		className={`absolute left-screen-step top-[69%] overflow-hidden lg:top-[var(--more-top)] ${
			code === 'ani'
				? 'lg:left-screen-step-19'
				: 'lg:left-[calc(3px+19*var(--screen-grid-step))]'
		}`}
		id={`${code}-see-more`}
		style={{ visibility: 'hidden', width: 0 }}
	>
		<CtaLink
			className="!w-full"
			to={to ?? portfolioLinks[code]}
			label={label ?? 'See more projects'}
			id={`${code}-see-more-cta`}
		/>
	</div>
)

function SloganElement() {
	const isMobile = getDeviceType() === 'mobile'

	return (
		<div
			className="absolute whitespace-pre-wrap font-headings text-[2rem] font-semibold leading-[1.1] text-white lg:whitespace-nowrap lg:text-[6rem] lg:leading-none"
			style={
				isMobile
					? {
							top: '69%',
							left: 'var(--screen-grid-step)',
					  }
					: {
							bottom: 'var(--seeing-bottom)',
							left: '50%',
							transform: 'translate(-50%)',
					  }
			}
			id="seeing"
		>
			<span className="seeing-word" style={{ visibility: 'hidden' }}>
				Seeing
			</span>{' '}
			<span className="seeing-word" style={{ visibility: 'hidden' }}>
				is
			</span>
			{isMobile ? '\n' : ' '}
			<span className="seeing-word" style={{ visibility: 'hidden' }}>
				Experiencing
			</span>
		</div>
	)
}

const ServiceElements = ({ services }) => (
	<>
		<ImgElement code="ext-i1" imageUrl={services[0].background.url} />
		<HeadingElement code="ext" words={['Exterior', 'Rendering']} />
		<ButtonElement
			code="ext"
			label={services[0].button_text}
			to={services[0].button_link}
		/>

		<ImgElement code="int-i1" imageUrl={services[1].background.url} />
		<HeadingElement code="int" words={['Interior', 'Rendering']} />
		<ButtonElement
			code="int"
			label={services[1].button_text}
			to={services[1].button_link}
		/>

		<div
			id="ani-wrapper"
			className="absolute top-0 h-screen w-full overflow-hidden duration-200 will-change-transform"
			style={{ transition: 'filter', visibility: 'hidden' }}
		>
			<FullScreenVideo id="ani-video" src={videoUrl} />
		</div>
		<HeadingElement code="ani" words={['Architectural', 'Animation']} />
		<ButtonElement code="ani" />

		<SloganElement />
	</>
)

const buildServiceAnimations = () => {
	const deviceType = getDeviceType()
	const defaults = { ease: 'none' }

	const interiorImg1 = () =>
		deviceType === 'desktop'
			? gsap
					.timeline({ id: 'ii1-tl', defaults })
					.call(
						() => {
							const videoEl = document.querySelector('#ani-video')

							videoEl.paused ? videoEl.play() : videoEl.pause()
						},
						null,
						0
					)
					.set('#int-i1-wrapper', { yPercent: 100, autoAlpha: 1 }, 0)
					.set('#int-heading', { autoAlpha: 1 }, 1.7)
					.set('#int-see-more', { autoAlpha: 1 }, 1.7)
					.to('#ext-i1', { scale: 0.7, autoAlpha: 0, duration: 2 }, 0)
					// .to('#ext-see-more-cta', { xPercent: 100, duration: 0.4 }, 0.2)
					.to('#int-i1-wrapper', { yPercent: 0, duration: 2 }, 0)
					.to(
						'.int-heading-word',
						{ autoAlpha: 1, stagger: 0.75, duration: 0.75 },
						1.75
					) // total duration of 1.5: 2 words + 1 stagger
					.to('#int-see-more', { width: 320, duration: 1.25 })
					.from('#int-i1', { scale: 1.3, duration: 1.75 }, 2)
					.set(
						['#ext-i1-wrapper', '#ext-heading', '#ext-see-more'],
						{ autoAlpha: 0 },
						2.5
					)
					.to({}, { duration: 0.5 }) // gap to breathe
			: gsap
					.timeline({ id: 'ii1-tl', defaults })
					.set('#int-i1-wrapper', { yPercent: 100, autoAlpha: 1 }, 0)
					.to('#ext-i1', { scale: 0.7, autoAlpha: 0, duration: 2 }, 0)
					.to('#int-i1-wrapper', { yPercent: 0, duration: 2 }, 0)
					.set('#int-heading', { autoAlpha: 1 }, 1.5)
					.to(
						'.int-heading-word',
						{ autoAlpha: 1, stagger: 0.75, duration: 0.75 },
						1.5
					)
					.to('#int-heading', { top: '-=96px', duration: 0.5 }, 3.5)
					.set('#int-see-more', { autoAlpha: 1 }, 4)
					.to(
						'#int-see-more',
						{ width: () => (14 * window.innerWidth) / 16, duration: 1 },
						4
					)
					.from('#int-i1', { scale: 1.4, duration: 2 }, 2)
					.call(
						() => {
							const videoEl = document.querySelector('#ani-video')

							videoEl.paused ? videoEl.play() : videoEl.pause()
						},
						null,
						0
					)
					.set(
						['#ext-i1-wrapper', '#ext-heading', '#ext-see-more'],
						{ autoAlpha: 0 },
						4
					)

	const animationVideo = () =>
		deviceType === 'desktop'
			? gsap
					.timeline({ id: 'av-tl', defaults })
					.call(() => document.querySelector('#ani-video').load(), null, 0)
					.set('#ani-wrapper', { autoAlpha: 1, yPercent: 100 }, 0)
					.set('#ani-heading', { autoAlpha: 1 }, 2.4)
					.set('#ani-see-more', { autoAlpha: 1 }, 2.4)
					.to('#int-i1', { scale: 0.7, autoAlpha: 0, duration: 3 }, 0)
					.to('#ani-wrapper', { yPercent: 0, duration: 3 }, 0)
					.call(() => document.querySelector('#ani-video').play(), null, 3)
					.to(
						'.ani-heading-word',
						{ autoAlpha: 1, stagger: 0.75, duration: 0.75 },
						2.5
					) // total duration of 1.5: 2 words + 1 stagger
					.to('#ani-see-more', { width: 370, duration: 1.25 }, 3.25)
					// .set('#ani-cover-wrapper', { autoAlpha: 0 }, 1.5)
					.set(
						[
							'#int-cover-wrapper',
							'#int-i1-wrapper',
							'#int-heading',
							'#int-see-more',
						],
						{ autoAlpha: 0 },
						3
					)
					.to({}, { duration: 1.5 }) // longer gap for video
			: gsap
					.timeline({ id: 'av-tl', defaults })
					// .load() pauses video and resets position to 0
					.call(() => document.querySelector('#ani-video').load(), null, 0)
					.set('#ani-wrapper', { autoAlpha: 1, yPercent: 100 }, 0)
					.to('#ani-wrapper', { yPercent: 0, duration: 2 }, 0)
					.call(() => document.querySelector('#ani-video').play(), null, 2)
					.set('#ani-heading', { autoAlpha: 1 }, 1.5)
					.to(
						'.ani-heading-word',
						{ autoAlpha: 1, stagger: 0.75, duration: 0.75 },
						1.5
					) // total duration of 1.5: 2 words + 1 stagger
					.to('#ani-heading', { top: '-=96px', duration: 0.5 }, 3.5)
					.set('#ani-see-more', { autoAlpha: 1 }, 4)
					.to(
						'#ani-see-more',
						{ width: () => (14 * window.innerWidth) / 16, duration: 1.5 },
						4
					)
					// .set('#ani-cover-wrapper', { autoAlpha: 0 }, 2)
					.to({}, { duration: 1.5 }) // longer gap for video
					.set(
						['#int-i1-wrapper', '#int-heading', '#int-see-more'],
						{ autoAlpha: 0 },
						5
					)

	return [interiorImg1, animationVideo]
}

export { ServiceElements, buildServiceAnimations }
